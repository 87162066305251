<template>
  <router-view v-if="ready">
    <o-map
      slot="map"
      ref="map"
    />
  </router-view>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 'App',

    computed: {
      ...mapState('app', ['ready']),
    },
  };
</script>

<style scoped lang='sass'>
  body
    overflow-y: hidden
</style>
