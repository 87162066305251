import Vue from 'vue';
import App from '@/App.vue';
import { sync } from 'vuex-router-sync';

Vue.config.productionTip = false;

fetch('/static/runtimeConfig.json')
  .then(data => data.json())
  .then(config => {
    Vue.prototype.RUNTIME_CONFIG = config;
  })
  .catch((e) => {
    console.log('Unable to load runtime config', e);
    Vue.prototype.RUNTIME_CONFIG = process.env;
  })
  .finally(() => {
    let plugins;

    const promises = [
      import('@/plugins').then((obj) => { plugins = obj; }),
      import('@/directives'),
    ];

    Promise.all(promises).then(() => {
      // Sync store with router
      sync(plugins.default.store, plugins.default.router);
      new Vue({
        ...plugins.default,

        created () {
          this.$store.dispatch('auth/loadUserFromToken')
            .then(() => this.$store.dispatch('app/loadParameters'))
            .then(() => this.$store.commit('app/setAppAsReady'))
          ;
        },

        render: h => h(App),
      }).$mount('#app');
    });
  })
;
